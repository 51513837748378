import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout/Layout';
import ExternalLink from '../components/ExternalLink';

export const frontmatter = {
  title: 'Datenschutz',
  footer: 2,
};

export default () => (
  <Layout>
    <Helmet>
      <title>Datenschutzerklärung</title>
    </Helmet>
    <h1>Datenschutzerklärung</h1>
    <p>
      Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
      EU-Datenschutzgrundverordnung (DSGVO), ist:
    </p>
    <p>
      Hirsch-Apotheke Korbach
      <br />
      Prof.-Kümmell-Str. 2<br />
      34497 Korbach
      <br />
      Inh. Pia Ueberson
    </p>

    <h2>Ihre Betroffenenrechte</h2>
    <p>
      Unter den angegebenen Kontaktdaten unserer Datenschutzbeauftragten können
      Sie jederzeit folgende Rechte ausüben:
    </p>
    <ul>
      <li>
        Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung
        (Art. 15 DSGVO),
      </li>
      <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
      <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
      <li>
        Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
        gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),
      </li>
      <li>
        Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO)
        und
      </li>
      <li>
        Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt
        haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).
      </li>
    </ul>
    <p>
      Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit
      mit Wirkung für die Zukunft widerrufen.
    </p>
    <p>
      Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde
      wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres
      Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige
      Behörde.
    </p>
    <p>
      Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit
      Anschrift finden Sie unter:{' '}
      <ExternalLink href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
        https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
      </ExternalLink>
      .
    </p>

    <h2>Erfassung allgemeiner Informationen beim Besuch unserer Website</h2>
    <h3>Art und Zweck der Verarbeitung:</h3>
    <p>
      Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht
      registrieren oder anderweitig Informationen übermitteln, werden
      automatisch Informationen allgemeiner Natur erfasst. Diese Informationen
      (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete
      Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre
      IP-Adresse und ähnliches.
    </p>
    <p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
    <ul>
      <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
      <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
      <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
      <li>zu weiteren administrativen Zwecken.</li>
    </ul>

    <p>
      Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen.
      Informationen dieser Art werden von uns ggfs. statistisch ausgewertet, um
      unseren Internetauftritt und die dahinterstehende Technik zu optimieren.
    </p>

    <h3>Rechtsgrundlage:</h3>
    <p>
      Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis
      unseres berechtigten Interesses an der Verbesserung der Stabilität und
      Funktionalität unserer Website.
    </p>
    <h3>Empfänger:</h3>
    <p>
      Empfänger der Daten sind ggf. technische Dienstleister, die für den
      Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter tätig
      werden.
    </p>
    <h3>Speicherdauer:</h3>
    <p>
      Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht
      mehr erforderlich sind. Dies ist für die Daten, die der Bereitstellung der
      Webseite dienen, grundsätzlich der Fall, wenn die jeweilige Sitzung
      beendet ist.
    </p>
    <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
    <p>
      Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder
      gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist jedoch
      der Dienst und die Funktionsfähigkeit unserer Website nicht gewährleistet.
      Zudem können einzelne Dienste und Services nicht verfügbar oder
      eingeschränkt sein. Aus diesem Grund ist ein Widerspruch ausgeschlossen.{' '}
    </p>

    <h2>Cookies</h2>
    <h3>Art und Zweck der Verarbeitung:</h3>
    <p>
      Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Bei
      Cookies handelt es sich um kleine Textdateien, die auf Ihrem Endgerät
      (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere
      Webseite besuchen.
    </p>
    <p>
      Hierdurch erhalten wir bestimmte Daten wie z. B. IP-Adresse, verwendeter
      Browser und Betriebssystem.
    </p>
    <p>
      Cookies können nicht verwendet werden, um Programme zu starten oder Viren
      auf einen Computer zu übertragen. Anhand der in Cookies enthaltenen
      Informationen können wir Ihnen die Navigation erleichtern und die korrekte
      Anzeige unserer Webseiten ermöglichen.
    </p>
    <p>
      In keinem Fall werden die von uns erfassten Daten an Dritte weitergegeben
      oder ohne Ihre Einwilligung eine Verknüpfung mit personenbezogenen Daten
      hergestellt.
    </p>
    <p>
      Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies
      betrachten. Internet-Browser sind regelmäßig so eingestellt, dass sie
      Cookies akzeptieren. Im Allgemeinen können Sie die Verwendung von Cookies
      jederzeit über die Einstellungen Ihres Browsers deaktivieren. Bitte
      verwenden Sie die Hilfefunktionen Ihres Internetbrowsers, um zu erfahren,
      wie Sie diese Einstellungen ändern können. Bitte beachten Sie, dass
      einzelne Funktionen unserer Website möglicherweise nicht funktionieren,
      wenn Sie die Verwendung von Cookies deaktiviert haben.
    </p>
    <h3>Speicherdauer und eingesetzte Cookies:</h3>
    <p>
      Soweit Sie uns durch Ihre Browsereinstellungen oder Zustimmung die
      Verwendung von Cookies erlauben, können folgende Cookies auf unseren
      Webseiten zum Einsatz kommen:
    </p>
    <p>Matomo, www.aponet.de, Mapbox.com</p>
    <p>
      Soweit diese Cookies (auch) personenbezogene Daten betreffen können,
      informieren wir Sie darüber in den folgenden Abschnitten.
    </p>
    <p>
      Sie können über Ihre Browsereinstellungen einzelne Cookies oder den
      gesamten Cookie-Bestand löschen. Darüber hinaus erhalten Sie Informationen
      und Anleitungen, wie diese Cookies gelöscht oder deren Speicherung vorab
      blockiert werden können. Je nach Anbieter Ihres Browsers finden Sie die
      notwendigen Informationen unter den nachfolgenden Links:
    </p>
    <ul>
      <li>
        Mozilla Firefox:{' '}
        <ExternalLink href="https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen">
          https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen
        </ExternalLink>
      </li>
      <li>
        Internet Explorer:{' '}
        <ExternalLink href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies">
          https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
        </ExternalLink>
      </li>
      <li>
        Google Chrome:{' '}
        <ExternalLink href="https://support.google.com/accounts/answer/61416?hl=de">
          https://support.google.com/accounts/answer/61416?hl=de
        </ExternalLink>
      </li>
      <li>
        Opera:{' '}
        <ExternalLink href="http://www.opera.com/de/help">
          http://www.opera.com/de/help
        </ExternalLink>
      </li>
      <li>
        Safari:{' '}
        <ExternalLink href="https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE">
          https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE
        </ExternalLink>
      </li>
    </ul>

    <h2>Löschung bzw. Sperrung der Daten</h2>
    <p>
      Wir halten uns an die Grundsätze der Datenvermeidung und Datensparsamkeit.
      Wir speichern Ihre personenbezogenen Daten daher nur so lange, wie dies
      zur Erreichung der hier genannten Zwecke erforderlich ist oder wie es die
      vom Gesetzgeber vorgesehenen vielfältigen Speicherfristen vorsehen. Nach
      Fortfall des jeweiligen Zweckes bzw. Ablauf dieser Fristen werden die
      entsprechenden Daten routinemäßig und entsprechend den gesetzlichen
      Vorschriften gesperrt oder gelöscht.
    </p>

    <h2>Verwendung von Matomo</h2>
    <h3>Art und Zweck der Verarbeitung:</h3>
    <p>
      Diese Website benutzt Matomo (ehemals Piwik), eine Open-Source-Software
      zur statistischen Auswertung von Besucherzugriffen. Anbieter der Software
      Matomo ist die InnoCraft Ltd., 150 Willis St, 6011 Wellington, Neuseeland.
    </p>
    <p>
      Matomo verwendet sog. Cookies, also Textdateien, die auf Ihrem Computer
      gespeichert werden und die eine Analyse der Benutzung der Website durch
      Sie ermöglichen.
    </p>
    <p>
      Die durch das Cookie erzeugten Informationen über Ihre Nutzung des
      Internetangebotes werden auf einem Server in Deutschland gespeichert.
    </p>
    <p>
      Die IP-Adresse wird unmittelbar nach der Verarbeitung und vor deren
      Speicherung anonymisiert. Sie haben die Möglichkeit, die Installation der
      Cookies durch Änderung der Einstellung Ihrer Browser-Software zu
      verhindern. Wir weisen Sie darauf hin, dass bei entsprechender Einstellung
      eventuell nicht mehr alle Funktionen dieser Website zur Verfügung stehen.
    </p>
    <p>
      Sie können sich entscheiden, ob in Ihrem Browser ein eindeutiger
      Webanalyse-Cookie abgelegt werden darf, um dem Betreiber der Webseite die
      Erfassung und Analyse verschiedener statistischer Daten zu ermöglichen.
    </p>
    <p>
      Nähere Informationen zu den Privatsphäre-Einstellungen der Matomo Software
      finden Sie unter folgendem Link:{' '}
      <ExternalLink href="https://matomo.org/docs/privacy/">
        https://matomo.org/docs/privacy/
      </ExternalLink>
      .
    </p>
    <h3>Empfänger:</h3>
    <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
    <h3>Speicherdauer:</h3>
    <p>
      Die Löschung der Daten erfolgt, sobald diese für unsere
      Aufzeichnungszwecke nicht mehr erforderlich sind.
    </p>
    <p>In unserem Fall geschieht die nach folgendem Zeitraum: 6 Monate.</p>
    <p>
      Wenn Sie mit der Speicherung und Auswertung dieser Daten aus Ihrem Besuch
      nicht einverstanden sind, dann können Sie der Speicherung und Nutzung
      nachfolgend per Mausklick jederzeit widersprechen. In diesem Fall wird in
      Ihrem Browser ein sog. Opt-Out-Cookie abgelegt, was zur Folge hat, dass
      Matomo keinerlei Sitzungsdaten erhebt. Bitte beachten Sie, dass die
      vollständige Löschung Ihrer Cookies zur Folge hat, dass auch das
      Opt-Out-Cookie gelöscht wird und ggf. von Ihnen erneut aktiviert werden
      muss.
    </p>
    <p>
      <iframe
        title="Matomo Opt-Out"
        style={{ border: 0, height: 220, width: 600 }}
        src="https://stats.hirsch-apotheke-korbach.de/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=&fontColor=&fontSize=&fontFamily="
      />
    </p>

    <h3>Profiling:</h3>
    <p>
      Mit Hilfe des Tracking-Tools Matomo kann das Verhalten der Besucher der
      Webseite bewertet und die Interessen analysiert werden. Hierzu erstellen
      wir ein pseudonymes Nutzerprofil.
    </p>

    <h2>Verwendung von Mapbox</h2>

    <p>
      Diese Webseite verwendet Mapbox API, um geographische Informationen
      visuell darzustellen. Bei der Nutzung von Mapbox-Karten werden von Mapbox
      auch Daten über die Nutzung der Kartenfunktionen durch Besucher erhoben,
      verarbeitet und genutzt. Nähere Informationen über die Datenverarbeitung
      durch Mapbox können Sie{' '}
      <ExternalLink href="https://www.mapbox.com/privacy/">
        den Mapbox-Datenschutzhinweisen
      </ExternalLink>{' '}
      entnehmen.
    </p>

    <h2>
      Ihre Rechte auf Auskunft, Berichtigung, Sperre, Löschung und Widerspruch
    </h2>

    <p>
      Sie haben das Recht, jederzeit Auskunft über Ihre bei uns gespeicherten
      personenbezogenen Daten zu erhalten. Ebenso haben Sie das Recht auf
      Berichtigung, Sperrung oder, abgesehen von der vorgeschriebenen
      Datenspeicherung zur Geschäftsabwicklung, Löschung Ihrer personenbezogenen
      Daten. Bitte wenden Sie sich dazu an unseren Datenschutzbeauftragten. Die
      Kontaktdaten finden Sie ganz unten.
    </p>

    <p>
      Damit eine Sperre von Daten jederzeit berücksichtigt werden kann, müssen
      diese Daten zu Kontrollzwecken in einer Sperrdatei vorgehalten werden. Sie
      können auch die Löschung der Daten verlangen, soweit keine gesetzliche
      Archivierungsverpflichtung besteht. Soweit eine solche Verpflichtung
      besteht, sperren wir Ihre Daten auf Wunsch.
    </p>

    <p>
      Sie können Änderungen oder den Widerruf einer Einwilligung durch
      entsprechende Mitteilung an uns mit Wirkung für die Zukunft vornehmen.
    </p>

    <h2>SSL-Verschlüsselung</h2>
    <p>
      Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden
      wir dem aktuellen Stand der Technik entsprechende
      Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
    </p>

    <h2>Änderung unserer Datenschutzbestimmungen</h2>

    <p>
      Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
      stets den aktuellen rechtlichen Anforderungen entspricht oder um
      Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B.
      bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die
      neue Datenschutzerklärung.
    </p>

    <h2>Fragen an die Datenschutzbeauftragte</h2>

    <p>
      Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail
      oder wenden Sie sich direkt an unsere Datenschutzbeauftragte:
    </p>

    <p>
      Frau Bärbel Kiel
      <br />
      Prof.-Kümmell-Str. 2<br />
      3449 Korbach
    </p>

    <p>
      <small>
        <em>
          Die Datenschutzerklärung wurde mit dem{' '}
          <ExternalLink href="https://www.activemind.de/datenschutz/datenschutzhinweis-generator/">
            Datenschutzerklärungs-Generator der activeMind AG erstellt
          </ExternalLink>
          .
        </em>
      </small>
    </p>
  </Layout>
);
